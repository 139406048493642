<template>
  <v-card style="padding: 20px">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Student Results</v-toolbar-title>
    </v-toolbar>
    <v-container style="margin-left: 0">
      <div v-for="(quiz, id) in answerQuizList" :key="id">
        <h2>Quiz Title: {{ quiz.quiz_name }}</h2>
        <h3>Total Marks: {{ quiz.total_question_marks }}</h3>
        <h4>
          Date/Time: {{ moment(quiz.quiz_date).format("Do MMMM YYYY") }},
          {{ moment(quiz.quiz_start_time, "HH:mm:ss").format("LT") }} -
          {{ moment(quiz.quiz_end_time, "HH:mm:ss").format("LT") }}
        </h4>
      </div>
    </v-container>

    <v-data-table :items="answerQuizList" :headers="headers">
      <template v-slot:item.actions="{ item }">
        <v-icon @click="deleteAnswerQuiz(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.show-results="{ item }">
        <v-btn @click="showResults(item)">Show Results</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showDialog" max-width="400px">
      <v-card>
        <v-card-title v-if="itemToDelete" class="headline">
          Do you really want to delete this quiz answer for
          {{ itemToDelete.user }}?
        </v-card-title>
        <!-- <v-card-text>
      <template v-if="itemToDelete">
        Do you really want to delete this quiz answer for {{ itemToDelete.user }}?
      </template>
    </v-card-text> -->
        <v-card-actions class="d-flex justify-end">
          <v-btn color="red darken-1" @click="confirmDelete">Yes</v-btn>
          <v-btn @click="cancelDelete">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisible" max-width="800px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ dialogData.title }} - Result For {{ dialogData.user }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="red"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-title></v-card-title>
        <v-card-text>
          <p style="padding: 3px; background-color: blanchedalmond">
            Total Achieved: {{ dialogData.total_marks }} out of
            {{ dialogData.total_question_marks }}
          </p>
          <ul>
            <li
              v-for="question in dialogData.questions"
              :key="question.question_text"
            >
              <strong>Question {{ question.question_text }}</strong>
              <strong style="float: right"
                >Mark {{ question.question_mark }}</strong
              >

              <ul>
                <li
                  v-for="option in question.options"
                  :key="option.option_text"
                >
                  <span
                    :class="{
                      'text-success': option.is_correct,
                      'text-error':
                        !option.is_correct &&
                        option.option_text === question.selected_options,
                    }"
                  >
                    {{ option.option_text }}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Student", value: "user" },
        { text: "Quiz", value: "quiz_name" },
        { text: "Actions", value: "actions" },
        { text: "Show Results", value: "show-results", align: "right" },
      ],
      dialogVisible: false,
      selectedQuiz: null,
      answerQuizList: [],
      dialogData: {},
      quizInfo: this.$route.params.id,
      showDialog: false,
      itemToDelete: null,
    };
  },
  mounted() {
    const quizId = this.$route.params.id;
    this.fetchStudentResults(quizId);
  },
  methods: {
    deleteAnswerQuiz(item) {
      this.itemToDelete = item;
      this.showDialog = true;
    },
    confirmDelete() {
      const item = this.itemToDelete;
      this.$api
        .delete(`/answers/${item.id}/`)
        .then(() => {
          const index = this.answerQuizList.findIndex(
            (quiz) => quiz.id === item.id
          );
          if (index > -1) {
            this.answerQuizList.splice(index, 1);
          }
          this.showDialog = false;
        })
        .catch((error) => {
          console.error(error);
          this.showDialog = false;
        });
    },
    cancelDelete() {
      this.showDialog = false;
    },

    fetchStudentResults(quizInfo) {
      this.$api
        .get(`/answersquiz/?quiz=${quizInfo}`)
        .then((response) => {
          this.answerQuizList = response.data.results.filter(
            (item) => item.quiz === quizInfo
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },

    showResults(item) {
      this.dialogData = {
        title: item.quiz_name,
        questions: item.questions,
        total_question_marks: item.total_question_marks,
        total_marks: item.total_marks,
        user: item.user,
      };
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.text-success {
  color: rgb(172, 231, 172);
}

.text-error {
  color: rgb(207, 139, 139);
}
</style>